import AwsAmplifyApi from "../../utility/AwsAmplifyApi";
import { APIEndpoints, APIPaths } from "../../config/APIEndpoints";
import { setPayload } from "../../utility/API";

import { errorActions, loaderActions } from ".";
import { LoaderContent } from "../../utility/constants/LoaderContent";

export const S_SCHEDULER_METADATA = "SCHEDULER_METADATA";
export const S_SCHEDULER_NOT_CHECKED_IN_PATIENTS = "patientsNotCheckedIn";
export const S_SCHEDULER_PATIENT_APPOINTMENTS = "patientAppointments";
export const S_SCHEDULER_PATIENT_APPOINTMENT_CALENDAR = "patientAppointmentCalendar";
export const S_SCHEDULER_APPOINTMENT_SUMMARY = "appointmentSummary";
export const S_SCHEDULER_OPT_APPOINTMENTS = "optimizedAppointments";
export const S_SCHEDULER_PATIENTS_MOVED_LIST = "patientsMovedList";
export const S_SCHEDULER_COMPLIANCE_METRICS = "patientsMetrics";
export const S_SCHEDULER_DATA_RESET = "SCHEDULER_DATA_RESET";
export const S_SCHEDULER_SETTING_DATA = "settingsData";
export const S_SCHEDULER_APP_SETTING_DATA = "appointmentSettingsData";


const updateErrorCode = err => dispatch => {
    dispatch(errorActions.resetCustomAuthenticationErrorCode(err));
};

const handleException = error => dispatch => {
    const status = error.response?.status
    if (status === 403 || status === 500) {
        dispatch(updateErrorCode(status));
    }
}

const dummyData = {
    [S_SCHEDULER_APP_SETTING_DATA]:{
        "data": [
            {
              "patient_id": 16230,
              "patient_first_name": "John ",
              "patient_last_name": "Reed",
              "patient_gender": "Male",
              "slots": {
                "monday": "09:00",
                "wednesday": "10:00"
              },
              "is_active": 0,
              "age": 70,
              "program": "MAT",
              "programs_desc": "MAT ",
              "discipline_idfier": "BHVL",
              "discipline": "Behavioral",
              "email": "john@gmail.com",
              "phone": "+1 123456789",
              "appointment_date": "2024-10-15T00:00:00"
            },
            {
              "patient_id": 16231,
              "patient_first_name": "John ",
              "patient_last_name": "Reed",
              "patient_gender": "Male",
              "is_active": 1,
              "slots": {
                "tuesday": "11:00",
                "thursday": "12:00",
                "friday": "14:00"
              },
              "age": 70,
              "program": "MAT",
              "programs_desc": "MAT ",
              "discipline_idfier": "MD",
              "discipline": "Medical",
              "email": "john@gmail.com",
              "phone": "+1 123456789",
              "appointment_date": "2024-10-16T00:00:00"
            },
            {
              "patient_id": 19292,
              "patient_first_name": "Victor",
              "patient_last_name": "Moreno",
              "patient_gender": "Male",
              "is_active": 1,
              "slots": {
                "friday": "15:00"
              },
              "age": 47,
              "program": "MAT",
              "programs_desc": "MAT ",
              "discipline_idfier": "BHVL",
              "discipline": "Behavioral",
              "email": "john@gmail.com",
              "phone": "+1 123456789",
              "appointment_date": "2024-10-18T00:00:00"
            },
            {
              "patient_id": 192912,
              "patient_first_name": "Victor",
              "patient_last_name": "Moreno",
              "patient_gender": "Male",
              "is_active": 1,
              "age": 47,
              "program": "MAT",
              "programs_desc": "MAT ",
              "discipline_idfier": "MD",
              "discipline": "Medical",
              "email": "john@gmail.com",
              "phone": "+1 123456789",
              "appointment_date": "2024-10-16T00:00:00"
            },
            {
              "patient_id": 386312,
              "patient_first_name": "Kimberly",
              "patient_last_name": "Servellon",
              "patient_gender": "Female",
              "is_active": 1,
              "age": 59,
              "program": "SUPPORTIVE_CARE",
              "programs_desc": "Supportive Care",
              "discipline_idfier": "PT",
              "discipline": "PT",
              "email": "john@gmail.com",
              "phone": "+1 123456789",
              "appointment_date": "2024-10-13T00:00:00"
            },
            {
              "patient_id": 38521,
              "patient_first_name": "Kathleen",
              "patient_last_name": "Smith",
              "patient_gender": "Female",
              "is_active": 1,
              "age": 55,
              "program": "SUPPORTIVE_CARE",
              "programs_desc": "Supportive Care",
              "discipline_idfier": "PT",
              "discipline": "PT",
              "email": "john@gmail.com",
              "phone": "+1 123456789",
              "appointment_date": "2024-10-14T00:00:00"
            },
            {
              "patient_id": 386311,
              "patient_first_name": "Kimberly",
              "patient_last_name": "Servellon",
              "patient_gender": "Female",
              "is_active": 1,
              "age": 59,
              "program": "SUPPORTIVE_CARE",
              "programs_desc": "Supportive Care",
              "discipline_idfier": "MD",
              "discipline": "Medical",
              "email": "john@gmail.com",
              "phone": "+1 123456789",
              "appointment_date": "2024-10-16T00:00:00"
            },
            {
              "patient_id": 386313,
              "patient_first_name": "Kimberly",
              "patient_last_name": "Servellon",
              "patient_gender": "Female",
              "is_active": 1,
              "age": 59,
              "program": "SUPPORTIVE_CARE",
              "programs_desc": "Supportive Care",
              "discipline_idfier": "BHVL",
              "discipline": "Behavioral",
              "email": "john@gmail.com",
              "phone": "+1 123456789",
              "appointment_date": "2024-10-12T00:00:00"
            },
            {
              "patient_id": 385221,
              "patient_first_name": "Kathleen",
              "patient_last_name": "Smith",
              "patient_gender": "Female",
              "is_active": 1,
              "age": 55,
              "program": "SUPPORTIVE_CARE",
              "programs_desc": "Supportive Care",
              "discipline_idfier": "BHVL",
              "discipline": "Behavioral",
              "email": "john@gmail.com",
              "phone": "+1 123456789",
              "appointment_date": "2024-10-11T00:00:00"
            },
            {
              "patient_id": 35161,
              "patient_first_name": "Nurit",
              "patient_last_name": "Friedlander",
              "patient_gender": "Female",
              "is_active": 1,
              "age": 60,
              "program": "SUPPORTIVE_CARE",
              "programs_desc": "Supportive Care",
              "discipline_idfier": "PT",
              "discipline": "PT",
              "email": "john@gmail.com",
              "phone": "+1 123456789",
              "appointment_date": "2024-10-16T00:00:00"
            },
            {
              "patient_id": 351621,
              "patient_first_name": "Nurit",
              "patient_last_name": "Friedlander",
              "patient_gender": "Female",
              "is_active": 1,
              "age": 60,
              "program": "SUPPORTIVE_CARE",
              "programs_desc": "Supportive Care",
              "discipline_idfier": "BHVL",
              "discipline": "Behavioral",
              "email": "john@gmail.com",
              "phone": "+1 123456789",
              "appointment_date": "2024-10-14T00:00:00"
            },
            {
              "patient_id": 38890,
              "patient_first_name": "Antonia \"Tonie\"",
              "patient_last_name": "Sanchez",
              "patient_gender": "Female",
              "is_active": 1,
              "age": 31,
              "program": "SUPPORTIVE_CARE",
              "programs_desc": "Same Day PT Auth, Supportive Care",
              "discipline_idfier": "PT",
              "discipline": "PT",
              "email": "john@gmail.com",
              "phone": "+1 123456789",
              "appointment_date": "2024-10-17T00:00:00"
            },
            {
              "patient_id": 388901,
              "patient_first_name": "Antonia \"Tonie\"",
              "patient_last_name": "Sanchez",
              "patient_gender": "Female",
              "is_active": 0,
              "age": 31,
              "program": "SUPPORTIVE_CARE",
              "programs_desc": "Same Day PT Auth, Supportive Care",
              "discipline_idfier": "BHVL",
              "discipline": "Behavioral",
              "email": "john@gmail.com",
              "phone": "+1 123456789",
              "appointment_date": "2024-10-16T00:00:00"
            }
          ],
        "error": ""
      }
};

const fetchPatientPreferencesApi = (queryString) => async dispatch => {
   
  
   const apiName = APIEndpoints.SMART_SCHEDULER_API.name;
   const apiPath = APIPaths.SMART_SCHEDULER_PATIENT_PREF;
   //const apiOptions = initializeAPIOptionsWithCId(selectedClinicId,token);
  
   const res = await dispatch(AwsAmplifyApi.get({apiName, apiPath,queryString}));
   
   //const data = dummyData[S_SCHEDULER_APP_SETTING_DATA]; 
   return res.data;
}

const updatePatientPreferencesApi = (body)=> async dispatch => {
   const apiName = APIEndpoints.SMART_SCHEDULER_API.name;
   const apiPath = APIPaths.SMART_SCHEDULER_UPDATE_PATIENT_PREF;
  
   const res = await dispatch(AwsAmplifyApi.post({apiName, apiPath,body}));
   
   //const data = dummyData[S_SCHEDULER_APP_SETTING_DATA]; 
   return res.data;
}

const fetchMetadataApi = (clinicId) => async dispatch => {

   const apiName = APIEndpoints.SMART_SCHEDULER_API.name;
   const apiPath = APIPaths.SMART_SCHEDULER_METADATA;

   const data = await dispatch(AwsAmplifyApi.get({apiName, apiPath, clinicId}));
   //return dummyData[S_SCHEDULER_METADATA];
   return data.data;
}

const fetchSettingsDataApi = (clinicId) => async dispatch => {
 const apiName = APIEndpoints.SMART_SCHEDULER_API.name;
 const apiPath = APIPaths.SMART_SCHEDULER_SETTINGS_METADATA;

 const data = await dispatch(AwsAmplifyApi.get({apiName, apiPath, clinicId}));
 //return dummyData[S_SCHEDULER_SETTING_DATA];
 return data.data;
}

const fetchPatientAppointmentsApi = (clinicId,queryString={}) => async dispatch => {
   const apiName = APIEndpoints.SMART_SCHEDULER_API.name;
   const apiPath = APIPaths.SMART_SCHEDULER_PATIENT_APPOINTMENTS;
   //console.log('fetchPatientAppointmentsApi',apiOptions);
   const data = await dispatch(AwsAmplifyApi.get({apiName, apiPath, clinicId,queryString}));
   //return dummyData[S_SCHEDULER_NOT_CHECKED_IN_PATIENTS].filter(p=>!dummyDeleted.includes(p.id));
   return data;
}

const fetchPatientAppointmentCalendarApi = (clinicId,queryString={}) => async dispatch => {
 const apiName = APIEndpoints.SMART_SCHEDULER_API.name;
 const apiPath = APIPaths.SMART_SCHEDULER_PATIENT_APPOINTMENT_CALENDAR;
 //console.log('fetchPatientAppointmentsApi',apiOptions);
 const data = await dispatch(AwsAmplifyApi.get({apiName, apiPath, clinicId,queryString}));
 //return dummyData[S_SCHEDULER_NOT_CHECKED_IN_PATIENTS].filter(p=>!dummyDeleted.includes(p.id));
 return data.data;
}

const fetchAppointmentSummaryApi = (clinicId,queryString={}) => async dispatch => {
   const apiName = APIEndpoints.SMART_SCHEDULER_API.name;
   const apiPath = APIPaths.SMART_SCHEDULER_APPOINTMENT_SUMMARY;
   //console.log('fetchPatientAppointmentsApi',apiOptions);
   const data = await dispatch(AwsAmplifyApi.get({apiName, apiPath, clinicId,queryString}));
   //return dummyData[S_SCHEDULER_NOT_CHECKED_IN_PATIENTS].filter(p=>!dummyDeleted.includes(p.id));
   return data.data;
}

const optimizePatientAppointmentsApi = (clinicId,queryString={}) => async dispatch => {
   const apiName = APIEndpoints.SMART_SCHEDULER_API.name;
   const apiPath = APIPaths.SMART_SCHEDULER_OPT_PATIENT_APPOINTMENTS;
   //console.log('fetchPatientAppointmentsApi',apiOptions);
   const data = await dispatch(AwsAmplifyApi.get({apiName, apiPath, clinicId,queryString}));
   //return dummyData[S_SCHEDULER_NOT_CHECKED_IN_PATIENTS].filter(p=>!dummyDeleted.includes(p.id));
   return data;
}

const fetchPatientsMovedListApi = (clinicId,queryString) => async dispatch => {
   //const qp = location?{location}:undefined;
   const apiName = APIEndpoints.SMART_SCHEDULER_API.name;
   const apiPath = APIPaths.SMART_SCHEDULER_PATIENT_MOVE_LIST;
   const data = await dispatch(AwsAmplifyApi.get({apiName, apiPath, clinicId,queryString}));
   return data;
   //return dummyData[S_SCHEDULER_PATIENTS_MOVED_LIST][filters.page];
}

const fetchPatientsComplianceMetricsApi = (clinicId,queryString) => async dispatch => {
   //const qp = location?{location}:undefined;
   const apiName = APIEndpoints.SMART_SCHEDULER_API.name;
   const apiPath = APIPaths.SMART_SCHEDULER_PATIENT_COMPLIANCE;
  
   const data = await dispatch(AwsAmplifyApi.get({apiName, apiPath, clinicId,queryString}));
   return data.data;
   //return dummyData[S_SCHEDULER_PATIENTS_MOVED_LIST][filters.page];
}

const fetchPatientsAuthInfoApi = (clinicId,queryString) => async dispatch => {
   //const qp = location?{location}:undefined;
   const apiName = APIEndpoints.SMART_SCHEDULER_API.name;
   const apiPath = APIPaths.SMART_SCHEDULER_PATIENT_AUTHORIZATION;
  
   const data = await dispatch(AwsAmplifyApi.get({apiName, apiPath, clinicId,queryString}));
   return data.data;
   //return dummyData[S_SCHEDULER_PATIENTS_MOVED_LIST][filters.page];
}

const updateAppointmentStatusApi = (clinicId,body) => async dispatch => {
   const apiName = APIEndpoints.SMART_SCHEDULER_API.name;
   const apiPath = APIPaths.SMART_SCHEDULER_UPDATE_APPOINTMENT_STATUS;

   const data = await dispatch(AwsAmplifyApi.post({apiName, apiPath, clinicId,body}));
   return data;
   //return dummyDeleted.push(body.appointmentId);
}

const updateSettingsApi = (clinicId,body) => async dispatch => {
   const apiName = APIEndpoints.SMART_SCHEDULER_API.name;
   const apiPath = APIPaths.SMART_SCHEDULER_UPDATE_SETTINGS;

   const data = await dispatch(AwsAmplifyApi.post({apiName, apiPath, clinicId,body}));
   return data;
   //return dummyDeleted.push(body.appointmentId);
}

const revertMoveApi = (clinicId,body) => async dispatch => {
   const apiName = APIEndpoints.SMART_SCHEDULER_API.name;
   const apiPath = APIPaths.SMART_SCHEDULER_REVERT_APPOINTMENT;

   const data = await dispatch(AwsAmplifyApi.post({apiName, apiPath, clinicId,body}));
   return data;
   //return dummyDeleted.push(body.moveId);
}

const fetchAppointmentNoteApi = (clinicId,appointmentId) => async dispatch => {
   const apiName = APIEndpoints.SMART_SCHEDULER_API.name;
   const apiPath = APIPaths.SMART_SCHEDULER_APPOINTMENT_NOTE(appointmentId);
  
   const data = await dispatch(AwsAmplifyApi.get({apiName, apiPath, clinicId}));
   return data.data;
   //return dummyDeleted.push(body.moveId); 
}

const fetchProvidersApi = (clinicId,queryString) => async dispatch => {
   const apiName = APIEndpoints.SMART_SCHEDULER_API.name;
   const apiPath = APIPaths.SMART_SCHEDULER_PROVIDERS;
  
   const data = await dispatch(AwsAmplifyApi.get({apiName, apiPath, queryString}));
   return data.data;
}

const updateAppointmentNoteApi = (clinicId,appointmentId,body) => async dispatch => {

   const apiName = APIEndpoints.SMART_SCHEDULER_API.name;
   const apiPath = APIPaths.SMART_SCHEDULER_APPOINTMENT_NOTE(appointmentId);
  
   const data = await dispatch(AwsAmplifyApi.patch({apiName, apiPath, clinicId,body}));
   return data;
   //return dummyDeleted.push(body.moveId); 
}

const updateProviderApi = (appointmentId,body) => async dispatch => {
   const apiName = APIEndpoints.SMART_SCHEDULER_API.name;
   const apiPath = APIPaths.SMART_SCHEDULER_APPOINTMENT_PROVIDER(appointmentId);
  
   const data = await dispatch(AwsAmplifyApi.patch({apiName, apiPath,body}));
   return data;
   //return dummyDeleted.push(body.moveId); 
}


export const fetchMetadata = (clinicId,loader=true) => async dispatch=>{
    try {
      loader && dispatch(loaderActions.startAppLoader(LoaderContent.GET_SMART_SCHEDULER_DETAILS));
        if (clinicId) {
            const response = await dispatch(fetchMetadataApi(clinicId));
            dispatch(setPayload(S_SCHEDULER_METADATA,response));
        }
    } catch (error) {
        dispatch(setPayload(S_SCHEDULER_METADATA,{}));
        dispatch(handleException(error));
    }
    finally{
      loader && dispatch(loaderActions.stopAppLoader());
    }
}

export const fetchSettingsData = (clinicId,loader=true) => async dispatch=>{
  try {
    loader && dispatch(loaderActions.startAppLoader(LoaderContent.GET_SMART_SCHEDULER_SETTINGS));
      if (clinicId) {
          const response = await dispatch(fetchSettingsDataApi(clinicId));
          dispatch(setPayload(S_SCHEDULER_SETTING_DATA,response,S_SCHEDULER_SETTING_DATA));
      }
  } catch (error) {
      dispatch(setPayload(S_SCHEDULER_SETTING_DATA,[],S_SCHEDULER_SETTING_DATA));
      dispatch(handleException(error));
  }
  finally{
      dispatch(loaderActions.stopAppLoader());
  }
}

export const fetchPatientPreferences = (filters) => async dispatch=>{
    try {
      dispatch(loaderActions.startAppLoader(LoaderContent.GET_SMART_SCHEDULER_PATIENT_PREF));
      const response = await dispatch(fetchPatientPreferencesApi(filters));
      //dispatch(setPayload(S_SCHEDULER_APP_SETTING_DATA,response,S_SCHEDULER_APP_SETTING_DATA));
      return response;
    } catch (error) {
        //dispatch(setPayload(S_SCHEDULER_APP_SETTING_DATA,{data:[]},S_SCHEDULER_APP_SETTING_DATA));
        dispatch(handleException(error));
        throw error?.response?.data;
    }
    finally{
      dispatch(loaderActions.stopAppLoader());
    }
}

export const fetchNotCheckedInPatients = (clinicId,filters) => async dispatch=>{
    try {
        dispatch(loaderActions.startAppLoader(LoaderContent.GET_SMART_SCHEDULER_DETAILS));
        if (clinicId) {
            const response = await dispatch(fetchPatientAppointmentsApi(clinicId,filters));
            dispatch(setPayload(S_SCHEDULER_NOT_CHECKED_IN_PATIENTS,response,S_SCHEDULER_NOT_CHECKED_IN_PATIENTS));
        }
    } catch (error) {
        dispatch(setPayload(S_SCHEDULER_NOT_CHECKED_IN_PATIENTS,{data:[]},S_SCHEDULER_NOT_CHECKED_IN_PATIENTS));
        dispatch(handleException(error));
    }
    finally{
        dispatch(loaderActions.stopAppLoader());
    }
}

export const fetchPatientAppointmentCalendar = (clinicId,filters,loader=true) => async dispatch=>{
  try {
    loader && dispatch(loaderActions.startAppLoader(LoaderContent.GET_PATIENT_APPOINTMENTS));
      if (clinicId) {
          const response = await dispatch(fetchPatientAppointmentCalendarApi(clinicId,filters));
          dispatch(setPayload(S_SCHEDULER_PATIENT_APPOINTMENT_CALENDAR,response));
      }
  } catch (error) {
      dispatch(setPayload(S_SCHEDULER_PATIENT_APPOINTMENT_CALENDAR,{}));
      dispatch(handleException(error));
  }
  finally{
    loader && dispatch(loaderActions.stopAppLoader());
  }
}

export const fetchPatientAppointments = (clinicId,filters) => async dispatch=>{
    try {
        dispatch(loaderActions.startAppLoader(LoaderContent.GET_PATIENT_APPOINTMENTS));
        if (clinicId) {
            const response = await dispatch(fetchPatientAppointmentsApi(clinicId,filters));
            dispatch(setPayload(S_SCHEDULER_PATIENT_APPOINTMENTS,response,S_SCHEDULER_PATIENT_APPOINTMENTS));
            return response;
        }
    } catch (error) {
        dispatch(setPayload(S_SCHEDULER_PATIENT_APPOINTMENTS,{data:[]},S_SCHEDULER_PATIENT_APPOINTMENTS));
        dispatch(handleException(error));
    }
    finally{
        dispatch(loaderActions.stopAppLoader());
    }
}

export const fetchAppointmentSummary = (clinicId,filters) => async dispatch=>{
    try {
        //dispatch(loaderActions.startAppLoader(LoaderContent.GET_PATIENT_APPOINTMENTS));
        if (clinicId) {
            const response = await dispatch(fetchAppointmentSummaryApi(clinicId,filters));
            dispatch(setPayload(S_SCHEDULER_APPOINTMENT_SUMMARY,response,S_SCHEDULER_APPOINTMENT_SUMMARY));
        }
    } catch (error) {
        dispatch(setPayload(S_SCHEDULER_APPOINTMENT_SUMMARY,{},S_SCHEDULER_APPOINTMENT_SUMMARY));
        dispatch(handleException(error));
    }
    finally{
        //dispatch(loaderActions.stopAppLoader());
    }
}

export const optimizePatientAppointments = (clinicId,filters) => async dispatch=>{
    try {
        dispatch(loaderActions.startAppLoader(filters.dryrun?LoaderContent.GET_OPT_PATIENT_APPOINTMENTS:LoaderContent.OPT_PATIENT_APPOINTMENTS));
        if (clinicId) {
            const response = await dispatch(optimizePatientAppointmentsApi(clinicId,filters));
            dispatch(setPayload(S_SCHEDULER_OPT_APPOINTMENTS,filters.dryrun?response:{data:{moves:[],skipped_moves:[]}},S_SCHEDULER_OPT_APPOINTMENTS));
        }
    } catch (error) {
        dispatch(setPayload(S_SCHEDULER_OPT_APPOINTMENTS,{data:{moves:[],skipped_moves:[]}},S_SCHEDULER_OPT_APPOINTMENTS));
        dispatch(handleException(error));
        throw error.response?.data;
    }
    finally{
        dispatch(loaderActions.stopAppLoader());
    }
}

export const fetchPatientsMovedList = (clinicId,filters) => async dispatch=>{
    try {
        dispatch(loaderActions.startAppLoader(LoaderContent.GET_SMART_SCHEDULER_DETAILS));
        if (clinicId) {
            const response = await dispatch(fetchPatientsMovedListApi(clinicId,filters));
            //console.log('fetchPatientsMovedList',response);
            dispatch(setPayload(S_SCHEDULER_PATIENTS_MOVED_LIST,response,S_SCHEDULER_PATIENTS_MOVED_LIST));
        }
    } catch (error) {
        //console.log('fetchPatientsMovedList:Err',error);
        dispatch(setPayload(S_SCHEDULER_PATIENTS_MOVED_LIST,{page_info:{total:0,page:1},data:[]},S_SCHEDULER_PATIENTS_MOVED_LIST));
        dispatch(handleException(error));
    }
    finally{
        dispatch(loaderActions.stopAppLoader());
    }
}

export const updateAppointmentStatus = (clinicId,payload) => async dispatch=>{
    try {
        dispatch(loaderActions.startAppLoader(LoaderContent.UPDATING_PATIENT_APPOINTMENT_STATUS));
        if (clinicId) {
            const response = await dispatch(updateAppointmentStatusApi(clinicId,payload));
        }
    } catch (error) {
        dispatch(handleException(error));
        throw error.response?.data;
    }
    finally{
        dispatch(loaderActions.stopAppLoader());
    }
}

export const updateSettings = (clinicId,payload) => async dispatch=>{
    try {
        dispatch(loaderActions.startAppLoader(LoaderContent.UPDATING_SMART_SCHEDULER_SETTINGS));
        if (clinicId) {
            const response = await dispatch(updateSettingsApi(clinicId,payload));
        }
    } catch (error) {
        dispatch(handleException(error));
        throw error.response?.data;
    }
    finally{
        dispatch(loaderActions.stopAppLoader());
    }
}

export const revertMove = (clinicId,payload) => async dispatch=>{
    try {
        dispatch(loaderActions.startAppLoader(LoaderContent.REVERT_PATIENT_MOVE));
        if (clinicId) {
            const response = await dispatch(revertMoveApi(clinicId,payload));
            return response;
        }
    } catch (error) {
        dispatch(handleException(error));
        throw error.response;
    }
    finally{
        dispatch(loaderActions.stopAppLoader());
    }
}

export const resetData = (properties) => async dispatch=>{
    try {
        dispatch(setPayload(S_SCHEDULER_DATA_RESET,{properties}))
    } catch (error) {

    }
}

export const fetchComplianceMetrics = (clinicId,filters,loader=false) => async dispatch=>{
    try {
        loader && dispatch(loaderActions.startAppLoader(LoaderContent.GET_PATIENT_COMPLIANCE_METRICS));
        if (clinicId) {
            const response = await dispatch(fetchPatientsComplianceMetricsApi(clinicId,filters));
            dispatch(setPayload(S_SCHEDULER_COMPLIANCE_METRICS,response,S_SCHEDULER_COMPLIANCE_METRICS));
        }
    } catch (error) {
        dispatch(handleException(error));
        dispatch(setPayload(S_SCHEDULER_COMPLIANCE_METRICS,{},S_SCHEDULER_COMPLIANCE_METRICS));
        throw error.response?.data;
    }
    finally{
        loader && dispatch(loaderActions.stopAppLoader());
    }
}

export const fetchPatientAuthInfo = (clinicId,filters,loader=true) => async dispatch=>{
    try {
        loader && dispatch(loaderActions.startAppLoader(LoaderContent.GET_PATIENT_AUTH_INFO));
        if (clinicId) {
            const response = await dispatch(fetchPatientsAuthInfoApi(clinicId,filters));
            return response;
        }
    } catch (error) {
        dispatch(handleException(error));
        throw error.response?.data;
    }
    finally{
        loader && dispatch(loaderActions.stopAppLoader());
    }
}

export const fetchAppointmentNote = (clinicId,appointmentId,loader=true) => async dispatch=>{
    try {
        loader && dispatch(loaderActions.startAppLoader(LoaderContent.GET_PATIENT_APPOINTMENT_NOTES));
        if (clinicId) {
            const response = await dispatch(fetchAppointmentNoteApi(clinicId,appointmentId));
            return response;
        }
    } catch (error) {
        //dispatch(handleException(error));
        throw error.response?.data;
    }
    finally{
        loader && dispatch(loaderActions.stopAppLoader());
    }
}



export const fetchProviders = (clinicId,queryString,loader=true) => async dispatch=>{
    try {
        loader && dispatch(loaderActions.startAppLoader(LoaderContent.GET_PATIENT_PROVIDERS));
        if (clinicId) {
            const response = await dispatch(fetchProvidersApi(clinicId,queryString));
            return response;
        }
    } catch (error) {
        //dispatch(handleException(error));
        throw error.response?.data;
    }
    finally{
        loader && dispatch(loaderActions.stopAppLoader());
    }
}



export const updateAppointmentNote = (clinicId,appointmentId,body) => async dispatch=>{
    try {
        dispatch(loaderActions.startAppLoader(LoaderContent.UPDATE_APPOINTMENT_DETAILS));
        if (clinicId) {
            const response = await dispatch(updateAppointmentNoteApi(clinicId,appointmentId,body));
            return response;
        }
    } catch (error) {
        //dispatch(handleException(error));
        throw error.response?.data;
    }
    finally{
        dispatch(loaderActions.stopAppLoader());
    }
}

export const updateAppointmentProvider = (appointmentId,body,loader=true) => async dispatch=>{
    try {
        loader && dispatch(loaderActions.startAppLoader(LoaderContent.UPDATE_APPOINTMENT_DETAILS));
        const response = await dispatch(updateProviderApi(appointmentId,body));
        return response;
    } catch (error) {
        //dispatch(handleException(error));
        throw error.response?.data;
    }
    finally{
        loader && dispatch(loaderActions.stopAppLoader());
    }
}

export const updatePatientPreferences = (body) => async dispatch=>{
    try {
        dispatch(loaderActions.startAppLoader(LoaderContent.UPDATE_PATIENT_PREF));
        const response = await dispatch(updatePatientPreferencesApi(body));
        return response;
    } catch (error) {
        //dispatch(handleException(error));
        throw error.response?.data;
    }
    finally{
        dispatch(loaderActions.stopAppLoader());
    }
}

