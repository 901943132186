import React, {useState,useContext} from "react";
import { useSelector,shallowEqual } from "react-redux";
import { Link,useNavigate } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import PropTypes from "prop-types";
import {PermissionContext} from "../../../PermissionContext";
import List from "../../../common/List";
import { useStyles } from "./styles";
import NavbarLink from "./NavbarLink";
import { navbarItems } from "./constant";
import { MenuItem } from "@mui/material";
import Menu from '@mui/material/Menu';
import SvgIcon from "../../../common/SvgIcon";
import Fade from '@mui/material/Fade';

const NavigationBar = props => {
  const { clinicId,clinicDashboards } = useSelector(state => ({
    clinicId:state.clinicReducer.selectedClinicId,
    clinicDashboards: state.clinicReducer.clinicDashboards
  }),shallowEqual);
  const navigate = useNavigate();
  const permissions = useContext(PermissionContext);
  const { headerColor } = props;
  const classes = useStyles(props);

    const [anchorE1, setAnchorE1] = useState({});

      const handleMenu =(menuId) => (event) => {
        setAnchorE1({[menuId]:event.currentTarget});
      };

      const handleClose = (e) => {
        e && e.stopPropagation()
        setAnchorE1({});
      };
  const navigateTo = (path)=>{
    navigate(path);
    handleClose();
    //console.log('navigating to',path);
  }
  const isActive = navbarItem => window.location.pathname.includes(navbarItem.activeLinks[0])

  const isHidden = navbarItem => navbarItem.isHidden?navbarItem.isHidden({...props,clinicDashboards,clinicId,permissions}):!hasPermission(navbarItem);
  const hasPermission = item => !item.privilege || permissions.includes(item.privilege)

  return (
    <nav className={classes.navigationLinks} >
      <List display="inline" className={classes.navlist}>
        {navbarItems.map(navbarItem => {
          if (navbarItem.type === "link") {
            return (
              isHidden(navbarItem)?'':<ListItem key={navbarItem.label} children={<NavbarLink {...navbarItem} headerColor={headerColor}  />} />
            );
          }
          if (navbarItem.type === "select"){
            return(isHidden(navbarItem)?'':
              <React.Fragment key={navbarItem.label}>
              <div className={classes.serviceSelectorContainer}>
              <div className={`${classes.dFlex} ${isActive(navbarItem)?classes.navLinkActive: classes.navLink}`} onClick={handleMenu(navbarItem.id)}>
                <SvgIcon icon={navbarItem.icon} className="mr-4"/> {navbarItem.label}
              </div>
              <Menu
                id={navbarItem.id}
                anchorEl={anchorE1[navbarItem.id]}
                keepMounted
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                autoFocus={false}
                open={Boolean(anchorE1[navbarItem.id])}
                onClose={handleClose}
                TransitionComponent={Fade}
              >
                {navbarItem.services.map(item => (
                  !hasPermission(item)?'':<MenuItem key={item.label} onClick={()=>navigateTo(item.to)}><Link className={`h-menuItem ${classes.dFlex} ${(window.location.pathname === item.to) ? classes.activeMenu : classes.menuItem}`} key={item.label} to={item.to} onClick={handleClose}><SvgIcon icon={item.icon} size="normal" className="mr-6"/>{item.label}</Link></MenuItem>
                ))}
              </Menu>
            </div>
            </React.Fragment>
            )
          }
          return null;
        })}
      </List>

    </nav>
  );
};

NavigationBar.propTypes = {
  navbarItems: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      activeLinks: PropTypes.arrayOf(PropTypes.string),
      label: PropTypes.string,
      openInNewTab: PropTypes.bool,
      to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    })
  ),
  headerColor: PropTypes.string,
};

export default NavigationBar;
