import { APIEndpoints, APIPaths } from "../../config/APIEndpoints";
import AwsAmplifyApi from "../../utility/AwsAmplifyApi";
import { CustomAPI } from "../../utility/CustomAPI";
import { SHOW_GLOBAL_ALERT } from "@utils/Events/constants";
import { loaderActions, errorActions } from "./";
import * as loginActions from "./UserActions/loginActions";
import { LoaderContent } from "../../utility/constants/LoaderContent";
import { fetchAuthenticatedUser,appContext } from "@redux/actionCreators/UserActions/loginActions";


export const UPDATE_USER_CLINICS="UPDATE_USER_CLINICS";
export const UPDATE_USER_SELECTED_CLINIC = "UPDATE_USER_SELECTED_CLINIC";

export const UPDATE_CLINIC_LOCATIONS = "UPDATE_CLINIC_LOCATIONS";

export const UPDATE_DASHBOARDS_LIST = "UPDATE_DASHBOARDS_LIST";

export const DATA_IMPORT_HISTORY_LIST = "DATA_IMPORT_HISTORY_LIST";

export const UPDATE_USER_INVITATIONS = "UPDATE_USER_INVITATIONS";

export const UPDATE_CLINIC_USERS = "UPDATE_CLINIC_USERS";

export const UPDATE_CLINIC_USER_METRICS = "UPDATE_CLINIC_USER_METRICS";

export const UPDATE_CLINIC_LOGO = "UPDATE_CLINIC_LOGO";

export const UPDATE_CLINIC_ACCESS_ROLES = "UPDATE_CLINIC_ACCESS_ROLES";
export const UPDATE_PLATFORM_PERMISSIONS = "UPDATE_PLATFORM_PERMISSIONS";
export const UPDATE_CLINIC_ALL_ROLES = "UPDATE_CLINIC_ALL_ROLES";


export const setUserClinics = userClinics => ({
    type: UPDATE_USER_CLINICS,
    payload: userClinics,
  });

export const setselectedclinicId = clinicId => ({
    type: UPDATE_USER_SELECTED_CLINIC,
    payload: clinicId,
  });

export const setClinicLocations = clinicLocations => ({
  type: UPDATE_CLINIC_LOCATIONS,
  payload: clinicLocations,
});

export const setDashboardsList = dashboards => ({
  type: UPDATE_DASHBOARDS_LIST,
  payload: dashboards,
});

export const setUserInvitations = userInvitations => ({
  type: UPDATE_USER_INVITATIONS,
  payload: userInvitations,
});

export const setClinicUsers = clinicUsers => ({
  type: UPDATE_CLINIC_USERS,
  payload: clinicUsers,
})

export const setClinicUserMetrics = clinicUsersMetrics => ({
  type: UPDATE_CLINIC_USER_METRICS,
  payload: clinicUsersMetrics,
});

export const setClinicLogo = (logo, clinicId) => dispatch => {
  const logoUrl = {
    logo,
    clinicId
  }
  dispatch({
    type: UPDATE_CLINIC_LOGO,
    payload: logoUrl,
  })
}

// To store all the deafult & custom role definitions with permissions
export const setClinicAccessRoles = (clinicAccessRoles) => dispatch => {
  const accessRoles =  groupByRoles(clinicAccessRoles);
  dispatch({
    type: UPDATE_CLINIC_ACCESS_ROLES,
    payload: accessRoles})

};

export const setPlatformPermissions = permissions => ({
  type: UPDATE_PLATFORM_PERMISSIONS,
  payload: permissions,
});

// To store all the deafult & custom role definitions only
export const setClinicAllRoles = clinicAllRoles => ({
  type: UPDATE_CLINIC_ALL_ROLES,
  payload: clinicAllRoles,
});

const groupByRoles = (clinicAccessRoles) =>{
  var groupedRoles = [];
  if(clinicAccessRoles !== undefined && clinicAccessRoles.length > 0){
    groupedRoles = clinicAccessRoles.filter(c => c.clinic_id === "platform");
  }
  for(let i=0; i<clinicAccessRoles.length; i++){
    if(clinicAccessRoles[i].clinic_id === "default"){
      groupedRoles.push(clinicAccessRoles[i]);
    }
  }
  for(let i=0; i<clinicAccessRoles.length; i++){
    if(clinicAccessRoles[i].clinic_id !== "default" && clinicAccessRoles[i].clinic_id !== "platform"){
      groupedRoles.push(clinicAccessRoles[i]);
    }
  }
  return groupedRoles;
}

const updateErrorCode = err => dispatch => {
  dispatch(errorActions.resetCustomAuthenticationErrorCode(err));
};

//*********************************************** code to be removed */
// const updateSubscribedServices = services => dispatch => {
//   dispatch(platformCoreActions.updateServiceSubscriptions(services));
// };
//***************************************************************** */
// *************************************************************************
// Fetch User Clinic Association
// *************************************************************************

const fetchUserClinicsAPI = () => async dispatch => {
    const apiName = APIEndpoints.NHCCLINICAPI.name;
    const apiPath = APIPaths.USER_CLINICS;

    return await dispatch(AwsAmplifyApi.get({apiName, apiPath,excludeClinicHeader:true}));

};
export const fetchUserClinics = (lastActiveClinic) => async dispatch => {
  try {

      const userClinics = await dispatch(fetchUserClinicsAPI());
      userClinics.data.sort((a, b) => {
        return a.name.localeCompare(b.name, undefined, {sensitivity: 'base'});
      });
      dispatch(setUserClinics(userClinics.data));

      if(userClinics.data.length > 0) {
        const defaultClinic = lastActiveClinic;
        const selectedclinicId = (defaultClinic?userClinics.data.find(c=>c.clinic_id == defaultClinic):null)?.clinic_id || userClinics.data[0].clinic_id;
        //console.log('defaultClinic',defaultClinic,selectedclinicId);
        dispatch(setselectedclinicId(selectedclinicId));
      }
      else {
        dispatch(setselectedclinicId(""));
        const logout = ()=>dispatch(loginActions.signout);
        _app.events.trigger(SHOW_GLOBAL_ALERT,{title:"Access Restricted",content:"We're sorry. You don't have access to the application.", okText:"Ok",onOkClick:logout});
      }

  } catch (error) {
      dispatch(setselectedclinicId(""));
      dispatch(setUserClinics([]));
      //throw error;
  }
};

export const resetUserClinics = () => async dispatch => {
    try {
      dispatch(setselectedclinicId(""));
      dispatch(setUserClinics([]));
    } catch (error) {
    }
  };

// *************************************************************************
// Clinic Data import
// *************************************************************************
const fetchImportUrlAPI = (clinicId,queryString)=> async dispatch =>{
  const apiName = APIEndpoints.NHCCLINICCOREAPI.name;
  const apiPath = APIPaths.IMPORT_FILE_URL;

  return await dispatch(AwsAmplifyApi.get({apiName, apiPath, queryString,clinicId}));
}

const fetchImportHistoryAPI = (clinicId,)=> async dispatch =>{
  const apiName = APIEndpoints.NHCCLINICCOREAPI.name;
  const apiPath = APIPaths.IMPORT_HISTORY_LIST;

  return await dispatch(AwsAmplifyApi.get({apiName, apiPath, clinicId}));
}

const importDataFileAPI = (clinicId,file,{url,fields})=> async dispatch =>{
  const body = {...fields,file};
  const headers = {"clinic-id": clinicId};
  const formFields = Object.keys(fields);
  formFields.push('file');
  const options = {fields:formFields};

  return await CustomAPI.formData(url,{body,headers,options});
}

export const importDataFile = (clinicId,importParams) => async dispatch => {
  try {
    const {file,...urlParams} = importParams;
    dispatch(loaderActions.startAppLoader(LoaderContent.CLINIC_REPORT_DATA_IMPORT));
    const urlRes = await dispatch(fetchImportUrlAPI(clinicId, urlParams));
    const response = await dispatch(importDataFileAPI(clinicId,file,urlRes.data.signedUrl));
    await dispatch(fetchImportHistory(clinicId));
    dispatch(loaderActions.stopAppLoader());
    return response;
  } catch (error) {
    dispatch(loaderActions.stopAppLoader());
    throw error;
  }
};

export const fetchImportUrl = (clinicId,importParams) => async dispatch => {
  try {

    dispatch(loaderActions.startAppLoader(LoaderContent.CLINIC_REPORT_DATA_IMPORT));
    const response = await dispatch(fetchImportUrlAPI(clinicId, importParams));

    dispatch(loaderActions.stopAppLoader());

    return response;
  } catch (error) {
    dispatch(loaderActions.stopAppLoader());
    throw error;
  }
};

export const fetchImportHistory = (clinicId) => async dispatch => {
  try {
    dispatch({
      type: DATA_IMPORT_HISTORY_LIST,
      payload: [],
    });
    dispatch(updateErrorCode(undefined));
    dispatch(loaderActions.startAppLoader(LoaderContent.CLINIC_DATA_IMPORT_HISTORY));
    const response = await dispatch(fetchImportHistoryAPI(clinicId));

    dispatch({
      type: DATA_IMPORT_HISTORY_LIST,
      payload: response.data?.rows || [],
    });
    dispatch(loaderActions.stopAppLoader());

  } catch (error) {
    dispatch(loaderActions.stopAppLoader());
    if(error.response.status === 403){
      dispatch(updateErrorCode(403))
    }
    //throw error;
  }
};
// *************************************************************************
// Update User Clinic Selection
// *************************************************************************

export const updateUserSelectedClinicId = clinicId => async dispatch => {
    await dispatch(setselectedclinicId(clinicId));
}

// *************************************************************************
// Get Dashboard URL
// *************************************************************************

const fetchDashboardURLAPI = (clinicId, childResource, dashboardName) => async dispatch => {

  const apiName = APIEndpoints.NHCCLINICAPI.name;
  //const apiPath = APIPaths.DASHBOARD_URL(clinicId, dashboardName);
  const apiPath = APIPaths.DASHBOARD_URL(childResource, dashboardName);

  return await dispatch(AwsAmplifyApi.get({apiName, apiPath,clinicId}));

};

export const fetchDashboardURL = (clinicId, childResource, dashboardName) => async dispatch => {

  let dashboardDetails = "";

  try {
      dispatch(updateErrorCode(undefined))
      dispatch(loaderActions.startAppLoader(LoaderContent.DASHBOARD));
      dashboardDetails = await dispatch(fetchDashboardURLAPI(clinicId, childResource, dashboardName));
      //dispatch(setUserClinics(userClinics.data));
      dispatch(loaderActions.stopAppLoader());

  } catch (error) {
      dispatch(loaderActions.stopAppLoader());
      if(error.response.status === 403){
        dispatch(updateErrorCode(403))
      }
  }
  return dashboardDetails;

};


// *************************************************************************
// Get All Dashboards
// *************************************************************************

const fetchDashboardsAPI = (clinicId) => async dispatch => {

  const apiName = APIEndpoints.NHCCLINICAPI.name;
  const apiPath = APIPaths.DASHBOARD_LIST;

  return await dispatch(AwsAmplifyApi.get({apiName, apiPath,clinicId}));

};

export const fetchDashboards = (clinicId) => async dispatch => {
  let clinicDashboards = {
    clinicId: {}
  }

  try {
      dispatch(loaderActions.startAppLoader(LoaderContent.DASHBOARD));
      const dashboardDetails = await dispatch(fetchDashboardsAPI(clinicId));
      const category = dashboardDetails.data.category.map(c=>({
        ...c,
        dashboards:c.dashboards?.filter(d=>d.is_active) || []
      })).filter(c=>c.dashboards.length > 0)
      /* dashboardDetails.data.category.map((item) => {
        return item.dashboards.sort((x,y) => {
          return (x === y)? 0 : x? 1 : -1;
      })}) */
      clinicDashboards = {
        init:true,
        [clinicId]: {category}
      }
      dispatch(setDashboardsList(clinicDashboards));
      dispatch(loaderActions.stopAppLoader());

  } catch (_error) {
      dispatch(setDashboardsList(clinicDashboards));
      dispatch(loaderActions.stopAppLoader());
      //throw error;
  }
};


// *************************************************************************
// Fetch Clinic Locations
// *************************************************************************

const fetchClinicLocationsAPI = (clinicId) => async dispatch => {

  const apiName = APIEndpoints.NHCCLINICCOREAPI.name;
  const apiPath = APIPaths.CLINIC_LOCATION;
  return await dispatch(AwsAmplifyApi.get({apiName, apiPath,clinicId}));

};

export const fetchClinicLocations = (clinicId) => async dispatch => {
  try {
      dispatch(updateErrorCode(undefined));
      dispatch(loaderActions.startAppLoader(LoaderContent.CLINIC_LOCATION_FETCH));
      const clinicLocations = await dispatch(fetchClinicLocationsAPI(clinicId));

      dispatch(setClinicLocations(clinicLocations.data));
      dispatch(loaderActions.stopAppLoader());

  } catch (error) {
      dispatch(setClinicLocations([]));
      dispatch(loaderActions.stopAppLoader());
      if(error.response.status === 403){
        dispatch(updateErrorCode(403));
      }
      throw error;
  }
};

// *************************************************************************
// Add Clinic Locations
// *************************************************************************

const addClinicLocationsAPI = (clinicId, body) => async dispatch => {

  const apiName = APIEndpoints.NHCCLINICCOREAPI.name;
  const apiPath = APIPaths.CLINIC_LOCATION;

  return await dispatch(AwsAmplifyApi.post({apiName, apiPath, clinicId,body}));

};

export const addClinicLocations = (clinicId, clinicLocationDetails) => async dispatch => {
  try {

      dispatch(loaderActions.startAppLoader(LoaderContent.CLINIC_LOCATION_UPDATE));
      await dispatch(addClinicLocationsAPI(clinicId, clinicLocationDetails));

      // Reload the data with all the locations
      await dispatch(fetchClinicLocations(clinicId));

      dispatch(loaderActions.stopAppLoader());

  } catch (error) {
      dispatch(loaderActions.stopAppLoader());
      throw error;
  }
};

// *************************************************************************
// Update Clinic Locations
// *************************************************************************

const updateClinicLocationsAPI = (clinicId, locationId, body) => async dispatch => {
  const apiName = APIEndpoints.NHCCLINICCOREAPI.name;
  const apiPath = APIPaths.CLINIC_LOCATION_UPDATE(locationId);

  return await dispatch(AwsAmplifyApi.put({apiName, apiPath, clinicId,body}));

};

export const updateClinicLocations = (clinicId, locationId, clinicLocationDetails) => async dispatch => {
  try {

      dispatch(loaderActions.startAppLoader(LoaderContent.CLINIC_LOCATION_UPDATE));
      await dispatch(updateClinicLocationsAPI(clinicId, locationId, clinicLocationDetails));

      // Reload the data with all the locations
      await dispatch(fetchClinicLocations(clinicId));

      dispatch(loaderActions.stopAppLoader());

  } catch (error) {
      dispatch(loaderActions.stopAppLoader());
      throw error;
  }
};

// *************************************************************************
// Fetch User Invitations
// *************************************************************************

const fetchUserInvitationsAPI = (clinicId) => async dispatch => {

  const apiName = APIEndpoints.NHCUSERAPI.name;
  const apiPath = APIPaths.USER_INVITATION_DETAILS;

  return await dispatch(AwsAmplifyApi.get({apiName, apiPath, clinicId}));

};

export const fetchUserInvitations = (clinicId) => async dispatch => {
  try {

      //dispatch(loaderActions.startAppLoader(LoaderContent.USER_INVITATION_FETCH));
      dispatch(loaderActions.startInlineLoader(loaderActions.SET_CLINIC_USER_INVITATION_LIST_LOADER));
      const userInvitations = await dispatch(fetchUserInvitationsAPI(clinicId));

      dispatch(setUserInvitations(userInvitations.data));
      //dispatch(loaderActions.stopAppLoader());
      dispatch(loaderActions.stopInlineLoader(loaderActions.SET_CLINIC_USER_INVITATION_LIST_LOADER));

  } catch (error) {
      dispatch(setUserInvitations([]));
      dispatch(loaderActions.stopInlineLoader(loaderActions.SET_CLINIC_USER_INVITATION_LIST_LOADER));
      //dispatch(loaderActions.stopAppLoader());
      throw error;
  }
};


// *************************************************************************
// Invite Users
// *************************************************************************

const inviteUsersAPI = (clinicId, body) => async dispatch => {

  const apiName = APIEndpoints.NHCUSERAPI.name;
  const apiPath = APIPaths.USER_INVITATION_DETAILS;

  return await dispatch(AwsAmplifyApi.post({apiName, apiPath, body,clinicId}));

};

export const inviteUsers = (clinicId, invitations) => async dispatch => {
  try {

      dispatch(loaderActions.startAppLoader(LoaderContent.USER_INVITATION_UPDATE));
      await dispatch(inviteUsersAPI(clinicId, invitations));

      await dispatch(fetchUserInvitations(clinicId));
      await dispatch(fetchUsersMetrics(clinicId));

      //dispatch(setUserInvitations(userInvitations.data));
      dispatch(loaderActions.stopAppLoader());

  } catch (error) {
      //dispatch(setUserInvitations([]));
      dispatch(loaderActions.stopAppLoader());
      throw error;
  }
};

// *************************************************************************
// Update Invitations
// *************************************************************************

const updateInvitedUserAPI = (clinicId, invitationId, email, roles, status) => async dispatch => {

  const body = {email, roles, status};

  const apiName = APIEndpoints.NHCUSERAPI.name;
  const apiPath = APIPaths.USER_INVITATION_UPDATE(invitationId);

  return await dispatch(AwsAmplifyApi.put({apiName, apiPath, body,clinicId}));

};

export const updateInvitedUser = (clinicId, invitationId, email, roles, status) => async dispatch => {
  try {

      dispatch(loaderActions.startAppLoader(LoaderContent.USER_INVITATION_UPDATE));
      await dispatch(updateInvitedUserAPI(clinicId, invitationId, email, roles, status));

      await dispatch(fetchUserInvitations(clinicId));
      await dispatch(fetchUsersMetrics(clinicId));

      dispatch(loaderActions.stopAppLoader());

  } catch (error) {
      dispatch(loaderActions.stopAppLoader());
      throw error;
  }
};

// *************************************************************************
// Delete User Invitation or Deactivate the User
// *************************************************************************

const deleteUserInvitationAPI = (clinicId, invitationId) => async dispatch => {

  const apiName = APIEndpoints.NHCUSERAPI.name;
  const apiPath = APIPaths.USER_INVITATION_UPDATE(invitationId);

  return await dispatch(AwsAmplifyApi.del({apiName, apiPath, clinicId}));

};

export const deleteUserInvitation = (clinicId, invitationId) => async dispatch => {
  try {

      dispatch(loaderActions.startAppLoader(LoaderContent.USER_INVITATION_UPDATE));
      await dispatch(deleteUserInvitationAPI(clinicId, invitationId));

      await dispatch(fetchUserInvitations(clinicId));
      await dispatch(fetchUsersMetrics(clinicId));

      dispatch(loaderActions.stopAppLoader());

  } catch (error) {
      dispatch(loaderActions.stopAppLoader());
      throw error;
  }
};

// *************************************************************************
// Resend User Invitation
// *************************************************************************

const resendUserInvitationAPI = (clinicId, invitationId) => async dispatch => {

  const apiName = APIEndpoints.NHCUSERAPI.name;
  const apiPath = APIPaths.USER_INVITATION_RESEND(invitationId);

  return await dispatch(AwsAmplifyApi.put({apiName, apiPath, clinicId}));

};

export const resendUserInvitation = (clinicId, invitationId) => async dispatch => {
  try {

      dispatch(loaderActions.startAppLoader(LoaderContent.USER_INVITATION_UPDATE));
      await dispatch(resendUserInvitationAPI(clinicId, invitationId));

      await dispatch(fetchUserInvitations(clinicId));
      await dispatch(fetchUsersMetrics(clinicId));

      dispatch(loaderActions.stopAppLoader());

  } catch (error) {
      dispatch(loaderActions.stopAppLoader());
      throw error;
  }
};

// *************************************************************************
// Fetch Clinic Users
// *************************************************************************

const fetchClinicUsersAPI = (clinicId) => async dispatch => {

  const apiName = APIEndpoints.NHCUSERAPI.name;
  const apiPath = APIPaths.USER_LIST;

  return await dispatch(AwsAmplifyApi.get({apiName, apiPath, clinicId}));

};

export const fetchClinicUsers = (clinicId) => async dispatch => {
  try {
      //dispatch(loaderActions.startAppLoader(LoaderContent.USER_FETCH));
      dispatch(updateErrorCode(undefined));
      dispatch(loaderActions.startInlineLoader(loaderActions.SET_CLINIC_USER_LIST_LOADER));
      const clinicUsers = await dispatch(fetchClinicUsersAPI(clinicId));
      dispatch(setClinicUsers(transformUserList(clinicUsers.data)));

      //dispatch(loaderActions.stopAppLoader());
      dispatch(loaderActions.stopInlineLoader(loaderActions.SET_CLINIC_USER_LIST_LOADER));
      return clinicUsers;
  } catch (error) {
      dispatch(setClinicUsers([]));
      //dispatch(loaderActions.stopAppLoader());
      dispatch(loaderActions.stopInlineLoader(loaderActions.SET_CLINIC_USER_LIST_LOADER));
      if(error.response.status === 403){
        dispatch(updateErrorCode(403));
      }
  }
};

const transformUserList = (sourceUserList) => {

  let userList = [];
  if(sourceUserList) {

    if(sourceUserList.users.active_users.length > 0) {

      userList = [...sourceUserList.users.active_users];

    }
    if(sourceUserList.users.deactivated_users.length > 0) {
      userList = [...userList, ...sourceUserList.users.deactivated_users]
    }

  }

  return userList;

}

// *************************************************************************
// Clinic User Update - Deactive and activate User
// *************************************************************************

const updateClinicUserAPI = (clinicId, email, roles, status) => async dispatch => {

  const body = {
    role_ids: roles,
    status
  };

  const apiName = APIEndpoints.NHCUSERAPI.name;
  const apiPath = APIPaths.USER_EDIT(email);

  return await dispatch(AwsAmplifyApi.put({apiName, apiPath, body,clinicId}));

};

export const updateClinicUser = (clinicId, email, roles, status) => async dispatch => {
  try {

    dispatch(loaderActions.startAppLoader(LoaderContent.USER_UPDATE));
    await dispatch(updateClinicUserAPI(clinicId, email, roles, status));

    await dispatch(fetchClinicUsers(clinicId));
    await dispatch(fetchUsersMetrics(clinicId));

    dispatch(loaderActions.stopAppLoader());

  } catch (error) {
      dispatch(loaderActions.stopAppLoader());
      throw error;
  }
};

// *************************************************************************
// Fetch User Metrics
// *************************************************************************
const fetchUsersMetricsAPI = (clinicId) => async dispatch => {
  const apiName = APIEndpoints.NHCUSERAPI.name;
  const apiPath = APIPaths.USER_METRICS;

  return await dispatch(AwsAmplifyApi.get({apiName, apiPath, clinicId}));

};

export const fetchUsersMetrics = (clinicId) => async dispatch => {
  try {

      const clinicUsersMetrics = await dispatch(fetchUsersMetricsAPI(clinicId));
      dispatch(setClinicUserMetrics(clinicUsersMetrics.data));

  } catch (error) {
      dispatch(setClinicUserMetrics({
        total_entries: 0,
        expired_invites: 0,
        pending_invites: 0,
        cancelled_invites: 0,
        active_users: 0,
        deactivated_users: 0
      }));
      throw error;
  }
};

//**************** Update clinic logo */

const updateLogoApi = (clinicId, body, type) => async dispatch =>{
  const headers = {
    "Accept": "*/*",
    "Content-Type": type,
    "clinic-id": clinicId
  }

  const apiName = APIEndpoints.NHCCLINICAPI.name;
  const apiPath = APIPaths.CLINIC_LOGO_UPDATE;

  return await dispatch(AwsAmplifyApi.post({apiName, apiPath,clinicId,body, headers}));
}

const updateLogoApi2 = (clinicId, body, type) => async dispatch =>{
  const { token } = await dispatch(fetchAuthenticatedUser());
  const api = APIEndpoints.NHCCLINICAPI.endpoint;
  const apiPath = APIPaths.CLINIC_LOGO_UPDATE;
  const headers = {
      "Accept": "*/*",
      "Content-Type":type,
      Authorization: token,
      "clinic-id": appContext.cid
  }
  return await CustomAPI.post(`${api}${apiPath}`,{body,headers});
}

export const updateLogo = (selectedClinicId, logo, type) => async dispatch => {
  try{
    if(logo !== undefined && selectedClinicId !== undefined){
      dispatch(loaderActions.startAppLoader(LoaderContent.LOGO_UPDATE));
    }
    const res = await dispatch(updateLogoApi2(selectedClinicId, logo, type));
    await dispatch(setClinicLogo(`${res.data}?t=${Date.now()}`, selectedClinicId));
    dispatch(loaderActions.stopAppLoader());
  }catch(error){
    dispatch(loaderActions.stopAppLoader());
    throw error;
  }
}


// *************************************************************************
// Fetch Clinic All Roles
// *************************************************************************

const fetchClinicAllRolesAPI = (clinicId) => async dispatch => {
  const apiName = APIEndpoints.NHCUSERACCESSAPI.name;
  const apiPath = APIPaths.ACCESS_ROLE;

  return await dispatch(AwsAmplifyApi.get({apiName, apiPath, clinicId}));

};

export const fetchClinicAllRoles = (clinicId) => async dispatch => {
  try {

      const clinicAllRoles = await dispatch(fetchClinicAllRolesAPI(clinicId));
      dispatch(setClinicAllRoles(clinicAllRoles.data));

  } catch (_error) {
      dispatch(setClinicAllRoles([]));
  }
};

// *************************************************************************
// Fetch Clinic Access Roles
// *************************************************************************

const fetchClinicAccessRolesAPI = (clinicId) => async dispatch => {

  const apiName = APIEndpoints.NHCUSERACCESSAPI.name;
  const apiPath = APIPaths.ACCESS_ROLE_DETAILED;

  return await dispatch(AwsAmplifyApi.get({apiName, apiPath, clinicId}));

};

export const fetchClinicAccessRoles = (clinicId) => async dispatch => {
  try {
      dispatch(updateErrorCode(undefined))
      dispatch(loaderActions.startInlineLoader(loaderActions.SET_CLINIC_USER_LIST_LOADER));

      const clinicAccessRoles = await dispatch(fetchClinicAccessRolesAPI(clinicId));
      dispatch(setClinicAccessRoles(orderRoles(clinicAccessRoles.data)));
      dispatch(storePlatformPermissions(clinicAccessRoles.data));

      dispatch(loaderActions.stopInlineLoader(loaderActions.SET_CLINIC_USER_LIST_LOADER));

  } catch (error) {
      dispatch(setClinicAccessRoles([]));
      dispatch(loaderActions.stopInlineLoader(loaderActions.SET_CLINIC_USER_LIST_LOADER));
      if(error.response.status === 403){
        dispatch(updateErrorCode(403));
      }
  }
};

const storePlatformPermissions = (clinicAccessRoles) => dispatch => {

  let permissions = [];
  try {

    if(clinicAccessRoles) {

      const defaultPermissions = clinicAccessRoles.filter(p => p.clinic_id === "default");
      if(defaultPermissions.length > 0 ) {
        permissions = defaultPermissions[0].permissions;
      }

    }
    dispatch(setPlatformPermissions(permissions));

  } catch(_error) {
    dispatch(setPlatformPermissions(permissions));
  }

}

const orderRoles = (roleList) => {

  let orderedRoleList = [];
  orderedRoleList = roleList.filter(p => p.clinic_id === "default");
  orderedRoleList = orderedRoleList.concat(roleList.filter(p => p.clinic_id !== "default"))
  return orderedRoleList;

}

// *************************************************************************
// Create Clinic Access Roles
// *************************************************************************
const createClinicAccessRolesAPI = (clinicId, roleName, roleDesc, rolePermissions) => async dispatch => {
  const apiName = APIEndpoints.NHCUSERACCESSAPI.name;
  const apiPath = APIPaths.ACCESS_ROLE;

  const body = {
    name: roleName,
    description: roleDesc,
    permissions: rolePermissions
  }

  return await dispatch(AwsAmplifyApi.post({apiName, apiPath, clinicId,body}));

};

export const createClinicAccessRoles = (clinicId, roleName, roleDesc, rolePermissions) => async dispatch => {
  try {

      dispatch(loaderActions.startAppLoader(LoaderContent.CREATE_ROLE));
      await dispatch(createClinicAccessRolesAPI(clinicId, roleName, roleDesc, rolePermissions));

      // call fetch API
      dispatch(fetchClinicAccessRoles(clinicId));
      dispatch(loaderActions.stopAppLoader());
  } catch (error) {
      dispatch(loaderActions.stopAppLoader());
      throw error;
  }
};

// *************************************************************************
// Update Clinic Role Permissions
// *************************************************************************
const updateClinicAccessRoleAPI = (clinicId, roleId, rolePermissions) => async dispatch => {

  const apiName = APIEndpoints.NHCUSERACCESSAPI.name;
  const apiPath = APIPaths.ACCESS_ROLE_OPERATION(roleId);

  const body = {
    permissions: rolePermissions
  }

  return await dispatch(AwsAmplifyApi.put({apiName, apiPath, clinicId,body}));

};

export const updateClinicAccessRole = (clinicId, roleId, rolePermissions) => async dispatch => {
  try {
      dispatch(loaderActions.startAppLoader(LoaderContent.UPDATE_ROLE));
      await dispatch(updateClinicAccessRoleAPI(clinicId, roleId, rolePermissions));

      // call fetch API
      dispatch(fetchClinicAccessRoles(clinicId));
      dispatch(loaderActions.stopAppLoader());

  } catch (error) {
      dispatch(loaderActions.stopAppLoader());
      throw error;
  }
};

// *************************************************************************
// Delete Clinic Role
// *************************************************************************
const deleteClinicAccessRoleAPI = (clinicId, roleId) => async dispatch => {
  const apiName = APIEndpoints.NHCUSERACCESSAPI.name;
  const apiPath = APIPaths.ACCESS_ROLE_OPERATION(roleId);

  return await dispatch(AwsAmplifyApi.del({apiName, apiPath, clinicId}));

};

export const deleteClinicAccessRole = (clinicId, roleId) => async dispatch => {
  try {
      dispatch(updateErrorCode(undefined));
      dispatch(loaderActions.startAppLoader(LoaderContent.DELETE_ROLE));
      await dispatch(deleteClinicAccessRoleAPI(clinicId, roleId));

      // call fetch API
      dispatch(fetchClinicAccessRoles(clinicId));
      dispatch(loaderActions.stopAppLoader());
  } catch (error) {
      dispatch(loaderActions.stopAppLoader());
      dispatch(updateErrorCode(error.response.status));
  }
};
